/* eslint-disable no-use-before-define */
import React, {
  useEffect,
  useCallback,
  shallowEqual,
 } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart2 as BarIcon,
  BookOpen as BookOpenIcon,
  List as ListIcon,
  DollarSign as DollarSignIcon,
  PieChart as PieChartIcon,
  PlusCircle as PlusIcon,
  HelpCircle as HelpIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import {GetAccessToken} from 'src/contexts/Auth0Context'
import { useSnackbar } from 'notistack';
import axios from 'src/utils/myAxios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useDispatch, useSelector } from 'src/store';
import { setLimits, setTier, setAccounts, setTransactions, setStrategies } from 'src/slices/limits';
import store from 'src/store';

const sections = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      },
      {
        title: 'Playbook',
        icon: BookOpenIcon,
        href: '/app/reports/playbook'
      },{
        title: 'Reports',
        icon: BarIcon,
        href: '/app/reports/detailed'
      }
    ]
  },
  {
    subheader: 'Trades',
    items: [
      {
        title: 'View Trades',
        icon: ListIcon,
        href: '/app/trades/view'
      },{
        title: 'Add Trades',
        icon: PlusIcon,
        href: '/app/trades/add'
      }
    ]
  },
  {
    subheader: 'Account',
    items: [
      {
        title: 'Subscription',
        href: '/app/pricing',
        icon: DollarSignIcon
      },{
        title: 'Contact Us',
        href: '/app/support',
        icon: HelpIcon
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
  updateFunction
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, updateFunction }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  updateFunction
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}

        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  var { tier } = useSelector((state) => state.limits, shallowEqual);

  const getAccountLimits = useCallback(async () => {
    try {
      const accessToken = await GetAccessToken()
      
      const response = await axios.get(`/user/limits`,
      {
        headers:{
          authorization: `Bearer ${accessToken}`
        }
      }
      );
      dispatch(setLimits(response.data))

    } catch (err) {
      console.error(err);
      var message = "Failed to get data"
      if (err.response) {
        if(err.response.status === 401) {
          message = "Unauthorized request. Try signing in again to fix the problem."
        }
        if(err.response.data !== undefined && err.response.data.message !== undefined){
          message = err.response.data.message
        }
        
      } else if (err.request) {
        message = "Failed to connect to backend"
      } else {
        // anything else
      }
      enqueueSnackbar(message, {
        variant: 'error'
      });
    }
    // eslint-disable-next-line
  }, [isMountedRef]);

  useEffect(() => {
    getAccountLimits();
  }, [getAccountLimits, isMountedRef]);


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo style={{width:50, height:50}}/>
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/support">
              <Logo style={{width:160}}/>
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/support"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
          </Box>
          <Box
            mt={1}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/pricing"
              variant="h5"
              color="primary"
              underline="none"
            >
              Your Plan: {store.getState().limits.tier}
            </Link>
          </Box>
          {
            store.getState().limits.tier === "Free" && 
            <Box
              mt={1}
              textAlign="center"
            >
              <Link
                component={RouterLink}
                to="/app/pricing"
                variant="h5"
                color={store.getState().limits.transactions < 80 ? "textPrimary": "error" }
                underline="none"
              >
                Transactions: {`${store.getState().limits.transactions === undefined ? 0 : store.getState().limits.transactions}/100`}
              </Link>
            </Box>
          }
          {
            store.getState().limits.tier === "Free" && 
            <Box
              mt={1}
              textAlign="center"
            >
              <Link
                component={RouterLink}
                to="/app/pricing"
                variant="h5"
                color={store.getState().limits.strategies < 2 ? "textPrimary": store.getState().limits.strategies === 2 ? "error" : "error"}
                underline="none"
              >
                Strategies: {`${store.getState().limits.strategies === undefined ? 0: store.getState().limits.strategies}/3`}
              </Link>
            </Box>
          }
          {
            store.getState().limits.tier !== "Premium" && 
            <Box
              mt={1}
              textAlign="center"
            >
              <Link
                component={RouterLink}
                to="/app/pricing"
                variant="h5"
                color={store.getState().limits.strategies < 1 ? "textPrimary": "error"}
                underline="none"
              >
                Accounts: {`${store.getState().limits.accounts === undefined ? 0: store.getState().limits.accounts}/${store.getState().limits.tier === "Free" ? 1 : 2}`}
              </Link>
            </Box>
          }
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                updateFunction: getAccountLimits
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Interested in being an affiliate?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/app/support"
            >
              Contact us
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
