import React from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Link,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const BottomBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="body2"
          color="textSecondary"
        >
          Copyright Virware LLC 2020 All Rights Reserved
        </Typography>
        <Box flexGrow={1} />
        <Link
          className={classes.link}
          color="textSecondary"
          component={Link}
          onClick={(e)=>{
            window.location="https://forms.gle/aKimmHnmtWDebS4s5"
          }}
          underline="none"
          variant="body2"
        >
          Afilliate Program
        </Link>
        <Link
          className={classes.link}
          color="textSecondary"
          underline="none"
          variant="body2"
          component={Link}
          onClick={(e)=>{
            window.location="https://www.iubenda.com/terms-and-conditions/67486640"
          }}
        >
          EULA
        </Link>
        <Link
          className={classes.link}
          color="textSecondary"
          underline="none"
          variant="body2"
          component={Link}
          onClick={(e)=>{
            window.location="https://www.iubenda.com/privacy-policy/67486640"
          }}
        >
          Privacy Policy
        </Link>
      </Toolbar>
  );
};

BottomBar.propTypes = {
  className: PropTypes.string
};

export default BottomBar;
