import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: 128
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  product: {
    position: 'relative',
    padding: theme.spacing(5, 3),
    cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  productImage: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  recommendedProduct: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  chooseButton: {
    backgroundColor: theme.palette.common.white
  },
  bottomItem:{
    paddingBottom: 80
  },
  bottomSpacing:{
    paddingBottom: 12
  }
}));

const Pricing = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      
      <Box mt="128px">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
          className={clsx(classes.bottomSpacing)}
        >
          Pricing
        </Typography>
        <Container maxWidth="lg">
          
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper
                className={classes.product}
                elevation={1}
              >
                <Typography
                  component="h3"
                  gutterBottom
                  variant="h2"
                  color="textSecondary"
                >
                  Trader Journal Free
                </Typography>
                <div>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                    color="textPrimary"
                  >
                    $0
                  </Typography>
                </div>
                <Typography
                  variant="overline"
                  color="textSecondary"
                >
                  No trial limits. Free forever
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  100 trades/month
                  <br />
                  1 account
                  <br />
                  3 strategies
                  <br />
                  Performance Reports
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.chooseButton}
                  component={RouterLink}
                  to="/app/pricing"
                >
                  Get Started
                </Button>
              </Paper>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper
                className={clsx(classes.product)}
                elevation={1}
              >
                <Typography
                  component="h3"
                  gutterBottom
                  variant="h2"
                  color="inherit"
                >
                  Trader Journal Plus
                </Typography>
                <div>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                    color="inherit"
                  >
                    $25.49
                  </Typography>
                  <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                    color="inherit"
                  >
                    /month billed anually
                  </Typography>
                </div>
                <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                    color="textSecondary"
                >
                  $29.99 /month billed monthly
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Typography
                  variant="body2"
                  color="inherit"
                >
                  Unlimited trade imports
                  <br />
                  2 accounts
                  <br />
                  Unlimited strategies
                  <br />
                  Strategy performance projections
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.chooseButton}
                  component={RouterLink}
                  to="/app/pricing"
                >
                  Get Started
                </Button>
                <Typography
                  variant="overline"
                  color="textSecondary"
                  mt={2}
                >
                  *7 day free trial with no credit card required
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper
                className={clsx(classes.product, classes.recommendedProduct)}
                elevation={1}
              >
                <Typography
                  component="h3"
                  gutterBottom
                  variant="h2"
                  color="textPrimary"
                >
                  Trader Journal Premium
                </Typography>
                <div>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                    color="textPrimary"
                  >
                    $42.49
                  </Typography>
                  <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    /month billed anually
                  </Typography>
                </div>
                <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                    color="textPrimary"
                >
                  $49.99 /month billed monthly
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  Unlimited accounts
                  <br />
                  Unlimited strategies
                  <br />
                  Risk tracking and analysis
                  <br />
                  Commission and fee tracking
                </Typography>
                <Box my={2}>
                  <Divider />
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.chooseButton}
                  component={RouterLink}
                  to="/app/pricing"
                >
                  Get Started
                </Button>
                <Typography
                  variant="overline"
                  color="textPrimary"
                  mt={2}
                >
                  *7 day free trial with no credit card required
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          
        </Container>
        <Box
          display="flex" 
          alignItems="center"
          justifyContent="center"
          mt={6}
        >
          <Button
            color="primary"
            variant="contained"
            align="center"
            component={RouterLink}
            
            to="/pricing"
          >
            More Details
          </Button>
        </Box>
        
      </Box>
    </div>
  );
};

Pricing.propTypes = {
  className: PropTypes.string
};

export default Pricing;
