import { 
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { ENABLE_REDUX_DEV_TOOLS } from 'src/constants';
import throttle from 'lodash.throttle';
import rootReducer from './rootReducer';

export const persistedState = loadState;

const store = configureStore({
  reducer: rootReducer,
  persistedState,
  devTools: ENABLE_REDUX_DEV_TOOLS
});

store.subscribe(throttle(() => {
  saveState(store.getState());
}, 1000));

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export function loadState() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}; 

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};


export default store;