import React from 'react';

const Logo = (props) => {
  return (
    <picture>
      <source srcset="/static/logo.webp" type="image/webp" {...props}/>
      <img src="/static/logo.png" alt="logo" {...props}/>
    </picture>
  );
}

export default Logo;
