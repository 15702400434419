import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useSelector, useDispatch, persistedState, loadState } from 'src/store';

const initialState = {
  tier: getTier(),
  transactions: getTransactions(),
  accounts: getAccounts(),
  strategies: getStrategies(),
};

const slice = createSlice({
  name: 'limits',
  initialState,
  reducers: {
    setLimits(state, limits){
      state.tier = limits.payload.limits.plan
      state.transactions = limits.payload.limits.transactions !== undefined ? limits.payload.limits.transactions : 0
      state.accounts = limits.payload.limits.accounts
      state.strategies = limits.payload.limits.strategies
    },
    setTier(state, tier){
      state.tier = tier.payload
    },
    setTransactions(state, transactions){
      state.transactions = transactions.payload
    },
    setAccounts(state, accounts){
      state.accounts = accounts.payload
    },
    setStrategies(state, strategies){
      state.strategies = strategies.payload
    }
  }
});

export const reducer = slice.reducer;

export const setLimits = (limits) => (dispatch) => {
  dispatch(slice.actions.setLimits(limits))
}

export const setTier = (tier) => async (dispatch) => {
    dispatch(slice.actions.setTier(tier))
}

export const setTransactions = (transactions) => async (dispatch) => {
  dispatch(slice.actions.setTransactions(transactions))
}

export const setAccounts = (accounts) => async (dispatch) => {
  dispatch(slice.actions.setAccounts(accounts))
}

export const setStrategies = (strategies) => async (dispatch) => {
  dispatch(slice.actions.setStrategies(strategies))
}

export default slice;

function getAccounts() {
  var accounts = 0;
  const limitState = loadState()
  if (limitState && limitState.limits && limitState.limits.accounts) {
      accounts = limitState.limits.accounts
  }
  return accounts
}

function getStrategies() {
  var strategies = 0;
  const limitState = loadState()
  if (limitState && limitState.limits && limitState.limits.strategies) {
    strategies = limitState.limits.strategies
  }
  return strategies
}

function getTier() {
  var tier = "Free";
  const limitState = loadState()
  if (limitState && limitState.limits && limitState.limits.tier) {
    tier = limitState.limits.tier
  }
  return tier
}

function getTransactions() {
  var transactions = 0;
  const limitState = loadState()
  if (limitState && limitState.limits && limitState.limits.transactions) {
    transactions = limitState.limits.transactions
  }
  return transactions
}