import _ from 'lodash';
import { loadState } from 'src/store';

import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch, persistedState } from 'src/store';

const initialState = {
  period: getPeriod(),
  accounts: getAccounts(),
  strategies: getStrategies(),
  symbols: getSymbols(),
  tags: getTags(),
  side: getSide(),
  type: getType(),
  startDate: getStartDate(),
  endDate: getEndDate(),
};

const slice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilters(state, newFilters) {
      state = newFilters.payload;
    },
    setPeriod(state, period) {
     state.period = period.payload;
    },
    setTags(state, tags) {
      state.tags = tags.payload;
    },
    setAccounts(state, accounts) {
      state.accounts = accounts.payload;
    },
    setSymbols(state, symbols) {
      state.symbols = symbols.payload;
    },
    setStrategies(state, strategies){
      state.strategies = strategies.payload;
    },
    setSide(state, side) {
      state.side = side.payload;
    },
    setPagination(state, pagination) {
      state.pagination = pagination.payload;
    },
    setStartDate(state, startDate) {
      state.startDate = startDate.payload;
    },
    setEndDate(state, endDate) {
      state.endDate = endDate.payload;
    },
    setType(state, type) {
      state.type = type.payload;
    }
  }
});

export const reducer = slice.reducer;

export const setFilters = (newFilters) => async(dispatch) => {
  dispatch(slice.actions.setFilters(newFilters))
}

export const setPeriod = (period) => async (dispatch) => {
    dispatch(slice.actions.setPeriod(period))
}

export const setTags = (tags) => async (dispatch) => {
  dispatch(slice.actions.setTags(tags))
}

export const setAccounts = (accounts) => async (dispatch) => {
  dispatch(slice.actions.setAccounts(accounts))
}

export const setSymbols = (symbols) => async (dispatch) => {
  dispatch(slice.actions.setSymbols(symbols))
}

export const setStrategies = (strategies) => async (dispatch) => {
  dispatch(slice.actions.setStrategies(strategies))
}

export const setSide = (side) => async (dispatch) => {
  dispatch(slice.actions.setSide(side))
}

export const setPagination = (pagination) => async (dispatch) => {
  dispatch(slice.actions.setPagination(pagination))
}

export const setStartDate = (startDate) => async (dispatch) => {
  dispatch(slice.actions.setStartDate(startDate))
}

export const setEndDate = (endDate) => async (dispatch) => {
  dispatch(slice.actions.setEndDate(endDate))
}

export const setType = (type) => async (dispatch) => {
  dispatch(slice.actions.setType(type))
}


export default slice;

function getPeriod() {
    var period = "week";
    const filterState = loadState()
    if (filterState && filterState.filters.period) {
        period = filterState.filters.period
    }
    return period
}

function getAccounts() {
  var accounts = [];
  const filterState = loadState()
  if (filterState && filterState.filters.accounts) {
      accounts = filterState.filters.accounts
  }
  return accounts
}

function getStrategies() {
  var strategies = [];
  const filterState = loadState()
  if (filterState && filterState.filters.strategies) {
    strategies = filterState.filters.strategies
  }
  return strategies
}

function getTags() {
  var tags = [];
  const filterState = loadState()
  if (filterState && filterState.filters.tags) {
    tags = filterState.filters.tags
  }
  return tags
}

function getSymbols() {
  var symbols = [];
  const filterState = loadState()
  if (filterState && filterState.filters.symbols) {
    symbols = filterState.filters.symbols
  }
  return symbols
}

function getSide() {
  var side = "All";
  const filterState = loadState()
  if (filterState && filterState.filters.side) {
    side = filterState.filters.side
  }
  return side
}

function getType() {
  var type = "$";
  const filterState = loadState()
  if (filterState && filterState.filters.type) {
    type = filterState.filters.type
  }
  return type
}

function getStartDate() {
  var startDate = null;
  const filterState = loadState()
  if (filterState && filterState.filters.startDate) {
    startDate = filterState.filters.startDate
  }
  return startDate
}

function getEndDate() {
  var endDate = null;
  const filterState = loadState()
  if (filterState && filterState.filters.endDate) {
    endDate = filterState.filters.endDate
  }
  return endDate
}