import { combineReducers } from '@reduxjs/toolkit';
import { reducer as filterReducer} from 'src/slices/filter'
import { reducer as limitsReducer} from 'src/slices/limits'

const rootReducer = combineReducers({
  filters: filterReducer,
  limits: limitsReducer
});

export default rootReducer;
