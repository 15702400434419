import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: 12,
    paddingBottom: 12
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  text: {
    marginTop: theme.spacing(2)
  }
}));

const Banner = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Holiday Special
        </Typography>
        <Typography
          className={clsx(classes.text)}
          variant="h3"
          align="center"
          color="textPrimary"
        >
          To help you make better trades next year, we're offering 60% off all our plans for 3 months.
        </Typography>
        <Typography
          className={clsx(classes.text)}
          variant="h3"
          align="center"
          color="textPrimary"
        >
          Use code TRADERJOURNAL2021 at checkout!
        </Typography>
      </Container>
    </div>
  );
};

Banner.propTypes = {
  className: PropTypes.string
};

export default Banner;
