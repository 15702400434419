import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import SplashScreen from 'src/components/SplashScreen';
import { auth0Config } from 'src/config';

let auth0Client = null;

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'Auth0',
  loginWithRedirect: () => Promise.resolve(),
  logout: () => { }
});

export async function GetAccessToken(){
  if (auth0Client !== null) {
    const accessToken = await auth0Client.getTokenSilently()
    return accessToken
  }
  return ""
}

function createCookie(name,value,days) {
  if (days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      var expires = "; expires="+date.toGMTString();
  }
  else var expires = "";
  document.cookie = name+"="+value+expires+"; path=/";
}

function eraseCookie(name) {
  createCookie(name,"",-1);
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const loginWithRedirect = async (options) => {

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if(code === undefined || code === null){
      await auth0Client.loginWithRedirect({
        redirect_uri: window.location.href
      });
    }
    

    const isAuthenticated = await auth0Client.isAuthenticated();

    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++){
      if(cookies[i].startsWith(" a0.spajs.txs.")){
        eraseCookie(cookies[i].split("=")[0]);
      }
    }

    if (isAuthenticated) {
      const user = await auth0Client.getUser();

      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            hmac: user["https://traderjournal.io/claims/hmac"]
          }
        }
      });
    }
  };

  const logout = () => {
    auth0Client.logout();

    dispatch({
      type: 'LOGOUT'
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          cacheLocation: 'localstorage',
          useRefreshTokens: true,
          ...auth0Config
        });

        try{
          await auth0Client.checkSession();
        }catch(e){
          console.log('getting here')
          //Clear local storage on failure and try again
          var keys = Object.keys(localStorage),
          i = keys.length;
          while (i-- ){
            console.log(keys[i])
            if (keys[i].includes('auth0spajs')){
              console.log("trying to delete")
              localStorage.removeItem(keys[i])
            }
          }
          auth0Client.logout()
        }

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = await auth0Client.getUser();

          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: {
                id: user.sub,
                avatar: user.picture,
                email: user.email,
                name: user.name,
                hmac: user["https://traderjournal.io/claims/hmac"]
              }
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Auth0',
        loginWithRedirect,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;