import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 80,
    paddingBottom: 80
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  },
  image: {
    '& > img': {
      maxWidth: 300,
      height: 'auto',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  bottomItem:{
    paddingBottom: 80
  }
}));

const FeatureBreakdown = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
            variant="h1"
            align="center"
            color="textPrimary"
            className={clsx(classes.bottomItem)}
          >
          Features
        </Typography>

        <Box width="100%" alignItems="center" alignContent="center">
          <Grid 
              container 
              spacing={4} 
              className={clsx(classes.bottomItem)}
              justify="center"
              alignItems="center">
            <Grid item
            xs={12}
            md={4}>
              <div>
                <picture className={classes.image}>
                  <source srcset="/static/home/Strategies.webp" type="image/webp"/>
                  <img src="/static/home/Strategies.jpg" alt="Strategies"/>
                </picture>
              </div>
            </Grid>
            <Grid item
            xs={12}
            md={4}>
              <Typography
                variant="h4"
                gutterBottom
                color="textPrimary"
              >
                Strategy Playbook
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
              >
                Track and compare the performance of your strategies. Find the ones that net you the most gains.
              </Typography>
            </Grid>
          </Grid>

          <Grid 
              container 
              spacing={4} 
              className={clsx(classes.bottomItem)}
              justify="center"
              alignItems="center">
            <Grid item
              xs={12}
              md={4}>
              <Typography
                variant="h4"
                gutterBottom
                color="textPrimary"
              >
                Reporting
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
              >
                Break down your performance in 50+ reports to find your specialty. Filter any report to drill down and identify winning patterns.
              </Typography>
            </Grid>
            <Grid item
            xs={12}
            md={4}>
              <div>
                <picture className={classes.image}>
                  <source srcset="/static/home/Reports.webp" type="image/webp"/>
                  <img src="/static/home/Reports.jpg" alt="Reports"/>
                </picture>
              </div>
            </Grid>
          </Grid>


          <Grid 
              container 
              spacing={4} 
              className={clsx(classes.bottomItem)}
              justify="center"
              alignItems="center">
            <Grid item
              xs={12}
              md={4}>
              <div>
                <picture className={classes.image}>
                  <source srcset="/static/home/Gain.webp" type="image/webp"/>
                  <img src="/static/home/Gain.jpg" alt="Gain"/>
                </picture>
              </div>
            </Grid>
            <Grid item
            xs={12}
            md={4}>
              <Typography
                variant="h4"
                gutterBottom
                color="textPrimary"
              >
                Risk Analysis
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
              >
                Track your stop per trade and view your performance reports by gain and R.
              </Typography>
            </Grid>
          </Grid>

        </Box>
      </Container>
    </div>
  );
};

FeatureBreakdown.propTypes = {
  className: PropTypes.string
};

export default FeatureBreakdown;
